import { useStaticQuery, graphql } from "gatsby"

const useIndexQuery = () => {
  const indexQuery = useStaticQuery(graphql`
    query indexQuery {
      allFile(filter: { name: { in: "click4fit-background" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `)
  return indexQuery
}

export default useIndexQuery
