import React from "react"
import { Section, Container, M, H2BlackTitleMargin } from "../../Global"
import TrainerCard from "../../Elements/TrainerCard"
import { TrainersGrid } from "./styles"

const TrainersComponent = () => {
  return (
    <Section id="trenerzy">
      <Container>
        <M>
          <H2BlackTitleMargin>MMA</H2BlackTitleMargin>
          <TrainersGrid>
            <TrainerCard />
            <TrainerCard />
            <TrainerCard />
            <TrainerCard />
            <TrainerCard />
            <TrainerCard />
          </TrainersGrid>
        </M>
      </Container>
    </Section>
  )
}

export default TrainersComponent
