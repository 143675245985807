import * as React from "react"
import Layout from "../components/UI/Layout"
import Head from "../components/Global/Head"
import TrainersComponent from "../components/Sections/trainers"
import useIndexQuery from "../data/query"

const Trainers = (props: any) => {
  const queryData = useIndexQuery()
  return (
    <Layout
      backgroundImage={
        queryData.allFile.edges[0].node.childImageSharp.gatsbyImageData
      }
    >
      <Head
        description="Trenerzy" //todo
        pageTitle="Trenerzy"
        keywords="platforma treningowa online, treningi online, "
      />
      <TrainersComponent />
    </Layout>
  )
}

export default Trainers
