import React, { useState, useEffect } from "react"
import {
  CardWrapper,
  ImageWrapper,
  DescWrapper,
  AuthorName,
  AuthorDesc,
} from "./styles"
import ITrainerCard from "./types"
import { Link } from "gatsby"
import author_picture from "../../../data/mockData/author1.jpg"

const TrainerCard = ({}: ITrainerCard) => {
  return (
    <Link to="/trainer-template/">
      <CardWrapper>
        <ImageWrapper>
          <img src={author_picture} />
        </ImageWrapper>
        <DescWrapper>
          <AuthorName>AUTHOR NAME</AuthorName>
          <AuthorDesc>
            lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. lorem
            ipsum dolor sit amet, consectetur.
          </AuthorDesc>
        </DescWrapper>
      </CardWrapper>
    </Link>
  )
}

export default TrainerCard
