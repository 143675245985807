import styled from "styled-components"

export const CardWrapper = styled.div`
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: row;
`

export const ImageWrapper = styled.div`
  width: 57%;
  height: 100%;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
export const DescWrapper = styled.div`
  width: 43%;
  height: 100%;
  display: flex;
  background-color: #262626;
  flex-direction: column;
`
export const AuthorName = styled.p`
  padding: 20px 10px 0px 10px;
  margin: 0;
  color: white;
`

export const AuthorDesc = styled.p`
  padding: 10px;
  margin: 0;
  color: white;
  font-size: 14px;
`
